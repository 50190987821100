import React from "react";

import { Maybe, SanitySecurityBrief } from "@graphql-types";
import { Container, MenuButtonGatsbyLink } from "@util/standard";
import styled from "styled-components";
import { MAX_WIDTH, TABLET_BREAKPOINT } from "@util/constants";
import Image from "@components/shared/sub/image";

interface Props {
  content: Maybe<SanitySecurityBrief> | undefined;
}

const StyledH2 = styled.h2`
  text-align: center;
  margin-bottom: 50px;
`;

const StyledImg = styled(Image)`
  width: 133px;
  height: 133px;
  margin: auto;
  margin-bottom: 25px;
`;

const StyledH4 = styled.h4`
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    text-align: center;
  }
`;

const StyledP = styled.p`
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    text-align: center;
  }
`;

const EnterpriseSecurityBrief = (props: Props) => {
  const { content } = props;

  if (content == null) return null;

  return (
    <Container
      width="95%"
      margin="auto"
      border
      paddingVertical={40}
      maxWidth={`${MAX_WIDTH}px`}
    >
      <StyledH2>{content.title}</StyledH2>
      <Container
        display="flex"
        flexWrap="wrap"
        gridGap="70px"
        padding="0px 40px 0px 40px"
      >
        {content.securityBriefItems?.map(brief => {
          if (brief == null) {
            return;
          }

          const { image, title, description } = brief;
          return (
            <Container width="265px" textAlign="center" margin="auto">
              {image && <StyledImg data={image} width="100%" />}
              <StyledH4>{title}</StyledH4>
              <StyledP>{description}</StyledP>
            </Container>
          );
        })}
      </Container>
      {content && content.link && (
        <MenuButtonGatsbyLink
          to={!!content.link.url ? content.link.url : ""}
          width="fit-content"
          margin="30px auto 0px auto"
          padding="10px 25px 10px 25px"
          isPink
        >
          {content.link.linktext}
        </MenuButtonGatsbyLink>
      )}
    </Container>
  );
};

export default EnterpriseSecurityBrief;
