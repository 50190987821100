import React, { useRef, useState, useEffect } from "react";
import { Carousel } from "antd";
import styled from "styled-components";
import Image from "@components/shared/sub/image";

import { Container, P, MenuButtonGatsbyLink } from "@util/standard";
import { icons, TABLET_BREAKPOINT, MAX_WIDTH } from "@util/constants";
import { Maybe, SanityCustomerLogos, SanityLink } from "@graphql-types";
import { CarouselProps, CarouselRef } from "antd/lib/carousel";

interface Props {
  bordered?: boolean;
  sliderContent?: Array<Object>;
  sliderHeader?: Maybe<string> | undefined;
  sliderButton?: Maybe<SanityLink> | undefined;
  isButton?: boolean;
  logos?: any;
  customerLogos?: SanityCustomerLogos[];
}
const SliderContainer = styled.div<{
  bordered: boolean;
}>`
  width: 85%;
  margin: 50px auto;
  max-width: ${MAX_WIDTH}px;
  position: relative;
  ${({ bordered }) =>
    bordered &&
    `
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding-top: 50px;
  padding-bottom: 50px;
  `};

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin: 0px auto 30px auto;
  }
`;
const Header = styled.h2`
  text-align: center;
  margin-bottom: 25px;
  color: black;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin-bottom: 30px;
  }
`;
const ImageWrapper = styled.div`
  width: 150px;
  height: auto;

  margin: auto;
  display: block;

  .gatsby-image-wrapper {
    display: block !important;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100px;
    p {
      font-size: 8px;
      line-height: 9px;
    }
  }
`;
const NavArrowLeft = styled.img`
  position: absolute;
  left: -10px;
  top: 45%;
`;
const NavArrowRight = styled.img`
  position: absolute;
  right: -10px;
  top: 45%;
`;

const CustomerLogoContainer = styled.div`
  height: 110px;
  padding: 0 10px;

  @media only screen and (min-width: ${TABLET_BREAKPOINT}px) {
    height: 180px;
  }
`;

function LogoSlider(props: Props) {
  if (props.logos == null && !props.customerLogos) return null;
  const carouselSlider = useRef<CarouselRef>(null);
  const {
    bordered,
    sliderContent,
    sliderHeader,
    sliderButton,
    isButton,
  } = props;
  const [isMobileWidth, toggleMobileWidth] = useState(false);
  const [shouldScroll, setShouldScroll] = useState(false);
  let carouselSettings: CarouselProps = {
    dots: false,
    infinite: shouldScroll,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    draggable: true,
  };

  let showArrows = isMobileWidth || props.logos?.logos?.length > 7;
  if (props.logos) {
    showArrows = showArrows || props.logos?.logos?.length > 7;
  } else {
    showArrows =
      showArrows ||
      (props.customerLogos ? props.customerLogos.length > 5 : false);
  }

  const checkScreenWidth = () => {
    if (window.innerWidth <= TABLET_BREAKPOINT) {
      toggleMobileWidth(true);
    } else {
      toggleMobileWidth(false);
    }
  };

  useEffect(() => {
    if (props.logos?.logos && props.logos?.logos?.length > 7) {
      setShouldScroll(true);
    } else if (props.customerLogos && props.customerLogos.length > 5) {
      setShouldScroll(true);
    } else {
      setShouldScroll(true);
    }
  }, [sliderContent]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      checkScreenWidth();
      window.addEventListener("resize", checkScreenWidth);
    }
    () => {
      window.removeEventListener("resize", checkScreenWidth);
    };
  }, []);

  return (
    <SliderContainer bordered={bordered ?? false}>
      <Header>{sliderHeader}</Header>
      {props.customerLogos && (
        <Carousel
          {...carouselSettings}
          ref={carouselSlider}
          slidesToShow={isMobileWidth ? 3 : 5}
        >
          {props.customerLogos.map(customerLogo => {
            if (!customerLogo.images) return <></>;
            return (
              <CustomerLogoContainer>
                <Image
                  key={customerLogo._key}
                  data={customerLogo.images}
                  objectFit="contain"
                  maxWidth="150px"
                  margin="auto"
                />
              </CustomerLogoContainer>
            );
          })}
        </Carousel>
      )}

      {!props.customerLogos && props.logos?.logos && (
        <Carousel
          {...carouselSettings}
          ref={carouselSlider}
          slidesToShow={isMobileWidth ? 3 : 7}
        >
          {props.logos.logos.map(slide => {
            return (
              <Container margin="auto" key={slide._key}>
                <ImageWrapper>
                  {slide.image && (
                    <Image
                      data={slide.image}
                      height="50%"
                      width="50%"
                      margin="auto"
                    />
                  )}
                  {slide.title && (
                    <P
                      width="70%"
                      margin="5px auto"
                      fontSize={12}
                      fontFamily="header bold"
                      textAlign="center"
                    >
                      {slide.title}
                    </P>
                  )}
                </ImageWrapper>
              </Container>
            );
          })}
        </Carousel>
      )}
      {showArrows && (
        <NavArrowLeft
          src={icons.leftArrow}
          alt="Nav Left"
          onClick={() => carouselSlider?.current?.prev()}
          width={28}
          height={28}
        />
      )}
      {showArrows && (
        <NavArrowRight
          src={icons.rightArrow}
          alt="Nav Right"
          onClick={() => carouselSlider?.current?.next()}
          width={28}
          height={28}
        />
      )}

      {sliderButton && isButton && (
        <MenuButtonGatsbyLink
          to={sliderButton.url ?? ""}
          width="fit-content"
          padding="10px 25px 10px 25px"
          margin="30px auto"
          isPink
        >
          {sliderButton.linktext}
        </MenuButtonGatsbyLink>
      )}
    </SliderContainer>
  );
}
export default LogoSlider;
